import * as basicLightbox from "basiclightbox";

class AudioModal {
    constructor(selector) {
        this.selector = selector;
        this._clickAudio = this._clickAudio.bind(this);
        this.selector.addEventListener('click', this._clickAudio);

        this.page_id = this.selector.getAttribute('data-page-id');
        console.log(this.page_id);
    }

    _getContentFromAPI(){
        let url = '/wp-json/wp/v2/pages/' + this.page_id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _clickAudio(e) {
        e.preventDefault();
        this._loadPageContent();
    }

    _loadPageContent() {
        this._getContentFromAPI().then(response => {
            if(response){
                console.log(response);
                this._showModal(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            console.log(error);
            this._handleError(error);
        })
    }

    _showModal(response) {
        console.log(response);

        if (response !== '') {
            const div = document.createElement('div');
            const modal = document.createElement('div');
            const close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '×';

            modal.classList.add('modal');
            modal.classList.add('modal--content')
            modal.setAttribute('data-element', 'modal');

            const content = document.createElement('div');
            content.classList.add('modal__content');
            content.innerHTML = response.content.rendered;

            modal.appendChild(close);
            modal.appendChild(content);
            div.appendChild(modal);

            let instance = basicLightbox.create(div);
            instance.show();

            close.addEventListener('click', (e) => {
                instance.close();
                instance.innerHTML = '';
            });
        }
    }
    _noResults(){
        const div = document.createElement('div');
        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal', 'modal--content');
        modal.setAttribute('data-element', 'modal');

        const content = document.createElement('div');
        content.classList.add('modal__content');
        content.innerText = 'Brak wpisów spełniających kryteria.';

        modal.appendChild(content);
        modal.appendChild(close);
        div.appendChild(modal);

        let instance = basicLightbox.create(div);
        instance.show();

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
    }

    _handleError(error){
        console.log(error);
        const div = document.createElement('div');
        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal', 'modal--content');
        modal.setAttribute('data-element', 'modal');

        const content = document.createElement('div');
        content.classList.add('modal__content');
        content.innerText = 'Wystąpił błąd podczas ładowania publikacji, odśwież stronę i spróbuj ponownie.';

        modal.appendChild(content);
        modal.appendChild(close);
        div.appendChild(modal);

        let instance = basicLightbox.create(div);
        instance.show();

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
    }
}
export default AudioModal;